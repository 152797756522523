// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs
import { designNameToClassName } from '@amedia/brick-classnames';

import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';
import * as theme from '../../build/style-dictionary/nettavisen/index.js';

export const nettavisen = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: { ...theme.fontSizes, titleCqiBase: '0.625rem' },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '6cqi',
    titleBreakingCqi: '6cqi',
    titleCommercialCqi: '6cqi',
  },
  other: theme.other,
};

export const nettavisenTheme = createTheme(
  designNameToClassName('nettavisen'),
  createCustomTheme(nettavisen)
);
