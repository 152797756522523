/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const leverNone = "0px";
export const leverS = "2px";
export const leverM = "10px";
export const leverL = "16px";
export const leverXl = "20px";
export const button = "16px";
export const teaser = "20px";
export const teaserImage = "16px";
export const card = "16px";
export const premiumIcon = "0px";
export const valgomat = "16px";
export const avatar = "9999px";
export const baseNone = "0px";
export const baseS = "2px";
export const baseM = "10px";
export const baseL = "16px";
export const baseXl = "20px";
export const baseMax = "9999px";
export const pill = "2px";
export const teaserCarousel = "10px";
