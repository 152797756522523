/**
 * Autogenerated file.
 * Built by @amedia/brick-tokens
 */

export const leverBase = "0.3125rem";
export const x2s = "0.5rem";
export const deckXGapM = "0.3125rem";
export const deckXGapL = "0.9375rem";
export const deckYGapM = "0.3125rem";
export const deckYGapL = "0.9375rem";
export const deckCommercialXGapM = "1.875rem";
export const deckCommercialXGapL = "1.875rem";
export const deckCommercialYGapM = "1.875rem";
export const deckCommercialYGapL = "1.875rem";
export const teaserXInsetM = "1.25rem";
export const teaserXInsetL = "1.25rem";
export const teaserYInsetM = "1.25rem";
export const teaserYInsetL = "1.25rem";
export const teaserContentXInsetM = "0rem";
export const teaserContentXInsetL = "0rem";
export const teaserContentYInsetM = "0.3125rem";
export const teaserContentYInsetL = "0.3125rem";
export const teaserBoxInsetM = "0.625rem";
export const teaserBoxInsetL = "0.625rem";
export const teaserStackM = "0rem";
export const teaserStackL = "0rem";
export const teaserStackAM = "0.625rem";
export const teaserStackAL = "0.9375rem";
export const teaserStackBM = "0.625rem";
export const teaserStackBL = "0.9375rem";
export const teaserStackCM = "0.9375rem";
export const teaserStackCL = "0.9375rem";
export const teaserStackDM = "0.9375rem";
export const teaserStackDL = "0.9375rem";
export const teaserStackTitleM = "0.625rem";
export const teaserStackTitleL = "0.625rem";
export const teaserNoImageInsetM = "1.25rem";
export const teaserNoImageInsetL = "1.25rem";
export const teaserBubbleInsetM = "0.9375rem";
export const teaserBubbleInsetL = "0.9375rem";
export const teaserSkinInsetM = "1.25rem";
export const teaserSkinInsetL = "1.25rem";
export const teaserFooterBylineTopM = "1.375rem";
export const teaserFooterBylineTopL = "1.375rem";
export const teaserFooterTipusTopM = "0.9375rem";
export const teaserFooterTipusRightM = "0rem";
export const teaserFooterTipusBottomM = "0rem";
export const teaserFooterTipusLeftM = "0rem";
export const teaserFooterTipusTopL = "0.9375rem";
export const teaserFooterTipusRightL = "0rem";
export const teaserFooterTipusBottomL = "0rem";
export const teaserFooterTipusLeftL = "0rem";
export const none = "0rem";
export const x0 = "0rem";
export const x1 = "0.3125rem";
export const x1s = "0.1875rem";
export const x2 = "0.625rem";
export const x3 = "0.9375rem";
export const x3s = "0.625rem";
export const x4 = "1.25rem";
export const x5 = "1.5625rem";
export const x6 = "1.875rem";
export const x7 = "2.1875rem";
export const x8 = "2.5rem";
export const x9 = "2.8125rem";
export const x10 = "3.125rem";
export const x11 = "3.4375rem";
export const x12 = "3.75rem";
export const x14 = "4.375rem";
export const x16 = "5rem";
export const x18 = "5.625rem";
export const x20 = "6.25rem";
export const x37 = "11.5625rem";
export const teaserMargin = "-3vw";
export const buttonPaddingtop = "0.75rem";
export const buttonPaddingright = "1.25rem";
export const buttonPaddingbottom = "0.8125rem";
export const buttonPaddingleft = "1.25rem";
export const topbannerYM = "0.9375rem";
export const topbannerYL = "1.25rem";
export const adnamiX = "0.9375rem";
