// typescript leaves import/exports statements alone when compiling, so
// we need to import from the compiled file which is .js, even though we
// have .ts on the filesystem.
//
// more here: https://devblogs.microsoft.com/typescript/announcing-typescript-4-7/#esm-nodejs

import { designNameToClassName } from '@amedia/brick-classnames';

import * as theme from '../../build/style-dictionary/alfa/index.js';
import { createCustomTheme } from '../createCustomeTheme.js';
import { createTheme } from '../../src/stitches.js';

const alfaConfig = {
  colors: theme.colors,
  fonts: theme.fonts,
  fontSizes: { ...theme.fontSizes, titleCqiBase: '1.063rem' },
  fontWeights: theme.fontWeights,
  lineHeights: theme.lineHeights,
  letterSpacings: theme.letterSpacings,
  space: theme.space,
  shadows: theme.shadows,
  radii: theme.radii,
  borderWidths: theme.borderWidths,
  sizes: {
    ...theme.space,
    ...theme.sizes,
    titleCqi: '5.5cqi',
    titleBreakingCqi: '5.5cqi',
    titleCommercialCqi: '5.5cqi',
  },
  other: theme.other,
};

export const alfaTheme = createTheme(
  designNameToClassName('alfa'),
  createCustomTheme(alfaConfig)
);
