export const fonts = {
baseTitlepieceM : "\"National2\", Helvetica, Arial, sans-serif",
baseTitlepieceL : "\"National2\", Helvetica, Arial, sans-serif",
baseIngressM : "\"National2\", Helvetica, Arial, sans-serif",
baseIngressL : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineXs : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineS : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineM : "\"National2\", Helvetica, Arial, sans-serif",
baseHeadlineL : "\"National2\", Helvetica, Arial, sans-serif",
baseSubheadS : "\"National2\", Helvetica, Arial, sans-serif",
baseSubheadM : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyS : "\"National2\", Helvetica, Arial, sans-serif",
baseBodyM : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionS : "\"National2\", Helvetica, Arial, sans-serif",
baseCaptionM : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaXs : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaS : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaM : "\"National2\", Helvetica, Arial, sans-serif",
baseMetaL : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityXs : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityS : "\"National2\", Helvetica, Arial, sans-serif",
baseUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularS : "\"National2\", Helvetica, Arial, sans-serif",
baseTabularM : "\"National2\", Helvetica, Arial, sans-serif",
baseBoostS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
baseBoostM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleBreakingDarkXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
pretitleXs : "\"National2\", Helvetica, Arial, sans-serif",
pretitleS : "\"National2\", Helvetica, Arial, sans-serif",
pretitleM : "\"National2\", Helvetica, Arial, sans-serif",
pretitleL : "\"National2\", Helvetica, Arial, sans-serif",
pretitleXl : "\"National2\", Helvetica, Arial, sans-serif",
pretitleXxl : "\"National2\", Helvetica, Arial, sans-serif",
pillM : "\"National2\", Helvetica, Arial, sans-serif",
pillBoldM : "\"National2\", Helvetica, Arial, sans-serif",
pillL : "\"National2\", Helvetica, Arial, sans-serif",
pillBoldL : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkXs : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkS : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkM : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkL : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkXl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleFeatureDarkXxl : "\"National2 Narrow\", Helvetica, Arial, sans-serif",
titleOpinionXs : "Tiempos Text",
titleOpinionS : "Tiempos Text",
titleOpinionM : "Tiempos Text",
titleOpinionL : "Tiempos Text",
titleOpinionXl : "Tiempos Text",
titleOpinionXxl : "Tiempos Text",
avatarMetaS : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaM : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaL : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleM : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXs : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailS : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXl : "\"National2\", Helvetica, Arial, sans-serif",
valgomatUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
tipusM : "\"National2\", Helvetica, Arial, sans-serif",
titleCommercialXs : "Merriweather, Georgia, \"Times New Roman\", serif",
titleCommercialXxl : "Merriweather, Georgia, \"Times New Roman\", serif",
adLabelM : "\"National2\", Helvetica, Arial, sans-serif",
}

export const fontSizes = {
baseTitlepieceM : "2rem",
baseTitlepieceL : "4.375rem",
baseIngressM : "1.5rem",
baseIngressL : "1.875rem",
baseHeadlineXs : "1rem",
baseHeadlineS : "1.125rem",
baseHeadlineM : "1.3125rem",
baseHeadlineL : "1.75rem",
baseSubheadS : "1rem",
baseSubheadM : "1.125rem",
baseBodyS : "1rem",
baseBodyM : "1.125rem",
baseCaptionS : "0.875rem",
baseCaptionM : "1rem",
baseMetaXs : "0.75rem",
baseMetaS : "0.8125rem",
baseMetaM : "0.875rem",
baseMetaL : "1rem",
baseUtilityXs : "0.9375rem",
baseUtilityS : "1.0625rem",
baseUtilityM : "1.125rem",
baseTabularS : "1rem",
baseTabularM : "1.125rem",
baseBoostS : "1.3125rem",
baseBoostM : "3.125rem",
titleXs : "1.25rem",
titleS : "1.75rem",
titleM : "2.5rem",
titleL : "2.25rem",
titleXl : "3.75rem",
titleXxl : "4.0625rem",
titleBreakingXs : "1.25rem",
titleBreakingS : "1.75rem",
titleBreakingM : "2.5rem",
titleBreakingL : "2.25rem",
titleBreakingXl : "3.75rem",
titleBreakingXxl : "4.0625rem",
titleBreakingDarkXs : "1.25rem",
titleBreakingDarkS : "1.75rem",
titleBreakingDarkM : "2.5rem",
titleBreakingDarkL : "2.25rem",
titleBreakingDarkXl : "3.75rem",
titleBreakingDarkXxl : "4.0625rem",
pretitleXs : "1.125rem",
pretitleS : "1.125rem",
pretitleM : "1.125rem",
pretitleL : "1.25rem",
pretitleXl : "1.25rem",
pretitleXxl : "1.25rem",
pillM : "0.8125rem",
pillBoldM : "0.8125rem",
pillL : "0.875rem",
pillBoldL : "0.875rem",
titleFeatureXs : "1.3125rem",
titleFeatureS : "1.3125rem",
titleFeatureM : "1.875rem",
titleFeatureL : "2.1875rem",
titleFeatureXl : "3.4375rem",
titleFeatureXxl : "3.75rem",
titleFeatureDarkXs : "1.3125rem",
titleFeatureDarkS : "1.3125rem",
titleFeatureDarkM : "1.875rem",
titleFeatureDarkL : "2.5rem",
titleFeatureDarkXl : "3.125rem",
titleFeatureDarkXxl : "3.75rem",
titleOpinionXs : "1.375rem",
titleOpinionS : "1.375rem",
titleOpinionM : "1.75rem",
titleOpinionL : "2rem",
titleOpinionXl : "3.125rem",
titleOpinionXxl : "3.125rem",
avatarMetaS : "0.8125rem",
avatarMetaM : "0.9375rem",
avatarMetaL : "1.125rem",
groupTitleM : "1.25rem",
groupTitleL : "1.75rem",
valgomatHeadline1M : "2rem",
valgomatHeadline1L : "2.25rem",
valgomatHeadline2M : "1.25rem",
valgomatHeadline2L : "1.5rem",
valgomatQuestionM : "1.125rem",
valgomatQuestionL : "1.5rem",
valgomatDetailXs : "0.875rem",
valgomatDetailS : "1rem",
valgomatDetailM : "1.25rem",
valgomatDetailL : "1.5rem",
valgomatDetailXl : "2.25rem",
valgomatUtilityM : "1.125rem",
tipusM : "1.125rem",
titleCommercialXs : "1.375rem",
titleCommercialXxl : "2.375rem",
adLabelM : "0.8125rem",
}

export const fontWeights = {
baseTitlepieceM : 700,
baseTitlepieceL : 700,
baseIngressM : 400,
baseIngressL : 400,
baseHeadlineXs : 700,
baseHeadlineS : 700,
baseHeadlineM : 700,
baseHeadlineL : 700,
baseSubheadS : 700,
baseSubheadM : 400,
baseBodyS : 400,
baseBodyM : 400,
baseCaptionS : 400,
baseCaptionM : 400,
baseMetaXs : 400,
baseMetaS : 400,
baseMetaM : 400,
baseMetaL : 400,
baseUtilityXs : 400,
baseUtilityS : 400,
baseUtilityM : 400,
baseTabularS : 400,
baseTabularM : 400,
baseBoostS : 800,
baseBoostM : 800,
titleXs : 700,
titleS : 700,
titleM : 700,
titleL : 700,
titleXl : 700,
titleXxl : 700,
titleBreakingXs : 800,
titleBreakingS : 800,
titleBreakingM : 800,
titleBreakingL : 800,
titleBreakingXl : 800,
titleBreakingXxl : 800,
titleBreakingDarkXs : 800,
titleBreakingDarkS : 800,
titleBreakingDarkM : 800,
titleBreakingDarkL : 800,
titleBreakingDarkXl : 800,
titleBreakingDarkXxl : 800,
pretitleXs : 400,
pretitleS : 400,
pretitleM : 400,
pretitleL : 400,
pretitleXl : 400,
pretitleXxl : 400,
pillM : 500,
pillBoldM : 500,
pillL : 500,
pillBoldL : 500,
titleFeatureXs : 700,
titleFeatureS : 700,
titleFeatureM : 700,
titleFeatureL : 700,
titleFeatureXl : 700,
titleFeatureXxl : 700,
titleFeatureDarkXs : 700,
titleFeatureDarkS : 700,
titleFeatureDarkM : 700,
titleFeatureDarkL : 700,
titleFeatureDarkXl : 700,
titleFeatureDarkXxl : 700,
titleOpinionXs : 500,
titleOpinionS : 500,
titleOpinionM : 500,
titleOpinionL : 500,
titleOpinionXl : 500,
titleOpinionXxl : 500,
avatarMetaS : 500,
avatarMetaM : 500,
avatarMetaL : 500,
groupTitleM : 700,
groupTitleL : 700,
valgomatHeadline1M : 400,
valgomatHeadline1L : 400,
valgomatHeadline2M : 400,
valgomatHeadline2L : 400,
valgomatQuestionM : 400,
valgomatQuestionL : 400,
valgomatDetailXs : 400,
valgomatDetailS : 400,
valgomatDetailM : 400,
valgomatDetailL : 400,
valgomatDetailXl : 500,
valgomatUtilityM : 400,
tipusM : 400,
titleCommercialXs : 700,
titleCommercialXxl : 700,
adLabelM : 500,
}

export const letterSpacings ={
baseTitlepieceM : "0rem",
baseTitlepieceL : "0rem",
baseIngressM : "0rem",
baseIngressL : "0rem",
baseHeadlineXs : "0rem",
baseHeadlineS : "0rem",
baseHeadlineM : "0rem",
baseHeadlineL : "0rem",
baseSubheadS : "0rem",
baseSubheadM : "0rem",
baseBodyS : "0rem",
baseBodyM : "0rem",
baseCaptionS : "0rem",
baseCaptionM : "0rem",
baseMetaXs : "0rem",
baseMetaS : "0rem",
baseMetaM : "0rem",
baseMetaL : "0rem",
baseUtilityXs : "-0.0099626412551359rem",
baseUtilityS : "-0.0099626412551359rem",
baseUtilityM : "-0.0099626412551359rem",
baseTabularS : "0rem",
baseTabularM : "0rem",
baseBoostS : "-0.0099626412551359rem",
baseBoostM : "-0.0099626412551359rem",
titleXs : "-0.0099626412551359rem",
titleS : "-0.0099626412551359rem",
titleM : "normal",
titleL : "-0.0099626412551359rem",
titleXl : "normal",
titleXxl : "normal",
titleBreakingXs : "-0.0099626412551359rem",
titleBreakingS : "-0.0099626412551359rem",
titleBreakingM : "normal",
titleBreakingL : "-0.0099626412551359rem",
titleBreakingXl : "normal",
titleBreakingXxl : "normal",
titleBreakingDarkXs : "-0.0099626412551359rem",
titleBreakingDarkS : "-0.0099626412551359rem",
titleBreakingDarkM : "normal",
titleBreakingDarkL : "-0.0099626412551359rem",
titleBreakingDarkXl : "normal",
titleBreakingDarkXxl : "normal",
pretitleXs : "-0.0099626412551359rem",
pretitleS : "1.095890538064949rem",
pretitleM : "-0.0099626412551359rem",
pretitleL : "-0.0199252825102718rem",
pretitleXl : "-0.0199252825102718rem",
pretitleXxl : "-0.0199252825102718rem",
pillM : "normal",
pillBoldM : "normal",
pillBoldL : "normal",
titleFeatureXs : "-0.0099626412551359rem",
titleFeatureS : "-0.0099626412551359rem",
titleFeatureM : "-0.0099626412551359rem",
titleFeatureL : "-0.0099626412551359rem",
titleFeatureXl : "-0.0099626412551359rem",
titleFeatureXxl : "-0.0099626412551359rem",
titleFeatureDarkXs : "-0.0099626412551359rem",
titleFeatureDarkS : "-0.0099626412551359rem",
titleFeatureDarkM : "-0.0099626412551359rem",
titleFeatureDarkL : "-0.0099626412551359rem",
titleFeatureDarkXl : "-0.0099626412551359rem",
titleFeatureDarkXxl : "-0.0099626412551359rem",
titleOpinionXs : "-0.0199252825102718rem",
titleOpinionS : "-0.0199252825102718rem",
titleOpinionM : "-0.0199252825102718rem",
titleOpinionL : "-0.0199252825102718rem",
titleOpinionXl : "-0.0199252825102718rem",
titleOpinionXxl : "-0.0199252825102718rem",
avatarMetaS : "",
avatarMetaM : "",
avatarMetaL : "",
groupTitleM : "-0.0199252825102718rem",
groupTitleL : "-0.0099626412551359rem",
valgomatHeadline1M : "0rem",
valgomatHeadline1L : "0rem",
valgomatHeadline2M : "",
valgomatHeadline2L : "",
valgomatQuestionM : "",
valgomatQuestionL : "",
valgomatDetailXs : "",
valgomatDetailS : "",
valgomatDetailM : "",
valgomatDetailL : "",
valgomatDetailXl : "",
tipusM : "-0.0099626412551359rem",
titleCommercialXs : "-0.0099626412551359rem",
titleCommercialXxl : "-0.0099626412551359rem",
adLabelM : "0.049813206275679506rem",
}

export const lineHeights = {
baseTitlepieceM : "1.05",
baseTitlepieceL : "1.05",
baseIngressM : "Auto",
baseIngressL : "Auto",
baseHeadlineXs : "1.2",
baseHeadlineS : "1.2",
baseHeadlineM : "1.2",
baseHeadlineL : "1.2",
baseSubheadS : "1.2",
baseSubheadM : "1.2",
baseBodyS : "1.4",
baseBodyM : "1.4",
baseCaptionS : "1.4",
baseCaptionM : "1.4",
baseMetaXs : "Auto",
baseMetaS : "Auto",
baseMetaM : "Auto",
baseMetaL : "Auto",
baseUtilityXs : "1.5",
baseUtilityS : "1.2",
baseUtilityM : "1.2",
baseTabularS : "Auto",
baseTabularM : "Auto",
baseBoostS : "1.05",
baseBoostM : "1.05",
titleXs : "0.95",
titleS : "0.95",
titleM : "0.95",
titleL : "0.95",
titleXl : "0.95",
titleXxl : "0.95",
titleBreakingXs : "0.95",
titleBreakingS : "0.95",
titleBreakingM : "0.95",
titleBreakingL : "0.95",
titleBreakingXl : "0.95",
titleBreakingXxl : "0.95",
titleBreakingDarkXs : "0.95",
titleBreakingDarkS : "0.95",
titleBreakingDarkM : "0.95",
titleBreakingDarkL : "0.95",
titleBreakingDarkXl : "0.95",
titleBreakingDarkXxl : "0.95",
pretitleXs : "1.1",
pretitleS : "1.1",
pretitleM : "1.1",
pretitleL : "1.1",
pretitleXl : "1.1",
pretitleXxl : "1.1",
pillM : "1.2",
pillBoldM : "1.2",
pillL : "1.2",
pillBoldL : "1.2",
titleFeatureXs : "0.95",
titleFeatureS : "0.95",
titleFeatureM : "0.95",
titleFeatureL : "0.95",
titleFeatureXl : "0.95",
titleFeatureXxl : "0.95",
titleFeatureDarkXs : "0.95",
titleFeatureDarkS : "0.95",
titleFeatureDarkM : "0.95",
titleFeatureDarkL : "0.95",
titleFeatureDarkXl : "0.95",
titleFeatureDarkXxl : "0.95",
titleOpinionXs : "1.1",
titleOpinionS : "1.1",
titleOpinionM : "1.1",
titleOpinionL : "1.1",
titleOpinionXl : "1",
titleOpinionXxl : "1",
avatarMetaS : "1.05",
avatarMetaM : "1.05",
avatarMetaL : "1.05",
groupTitleM : "0.94",
groupTitleL : "0.94",
valgomatHeadline1M : "1.25",
valgomatHeadline1L : "1.25",
valgomatHeadline2M : "1.5",
valgomatHeadline2L : "1.5",
valgomatQuestionM : "1.5",
valgomatQuestionL : "1.5",
valgomatDetailXs : "1.5",
valgomatDetailS : "1.5",
valgomatDetailM : "1.5",
valgomatDetailL : "1.5",
valgomatDetailXl : "1.5",
valgomatUtilityM : "1.5",
tipusM : "1.1",
titleCommercialXs : "1.1",
titleCommercialXxl : "1.1",
}
