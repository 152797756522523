export const fonts = {
baseTitlepieceM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseTitlepieceL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseBoostS : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseBoostM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseIngressM : "Crimson Pro",
baseIngressL : "Crimson Pro",
baseHeadlineXs : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseHeadlineS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseHeadlineM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseHeadlineL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
baseSubheadS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseSubheadM : "Crimson Pro",
baseBodyS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseBodyM : "Crimson Pro",
baseCaptionS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseCaptionM : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseMetaXs : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseMetaS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseMetaM : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseMetaL : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseUtilityS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseUtilityXs : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseUtilityM : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseTabularS : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
baseTabularM : "Crimson Pro",
pillM : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
pillBoldM : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
pillL : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
pillBoldL : "\"ALT Rund Text\", Poppins, \"Open Sans\", sans-serif",
titleXs : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleS : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleXl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleXxl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleOpinionXs : "Crimson Pro",
titleOpinionS : "Crimson Pro",
titleOpinionM : "Crimson Pro",
titleOpinionL : "Crimson Pro",
titleOpinionXl : "Crimson Pro",
titleOpinionXxl : "Crimson Pro",
titleFeatureM : "Crimson Pro",
titleFeatureXs : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureS : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureL : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureXl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXs : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkS : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkM : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkL : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXl : "\"National2\", Helvetica, Arial, sans-serif",
titleFeatureDarkXxl : "\"National2\", Helvetica, Arial, sans-serif",
titleBreakingXs : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingS : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingXl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingXxl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkXs : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkS : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkXl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
titleBreakingDarkXxl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleXs : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleS : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleM : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleL : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleXl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
pretitleXxl : "\"ALT Rund Display\", Poppins, \"Open Sans\", sans-serif",
valgomatHeadline1M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline1L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2M : "\"National2\", Helvetica, Arial, sans-serif",
valgomatHeadline2L : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatQuestionL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXs : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailS : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailM : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailL : "\"National2\", Helvetica, Arial, sans-serif",
valgomatDetailXl : "\"National2\", Helvetica, Arial, sans-serif",
valgomatUtilityM : "\"National2\", Helvetica, Arial, sans-serif",
tipusM : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleM : "\"National2\", Helvetica, Arial, sans-serif",
groupTitleL : "\"National2\", Helvetica, Arial, sans-serif",
titleCommercialXs : "Merriweather, Georgia, \"Times New Roman\", serif",
titleCommercialXxl : "Merriweather, Georgia, \"Times New Roman\", serif",
avatarMetaS : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaM : "\"National2\", Helvetica, Arial, sans-serif",
avatarMetaL : "\"National2\", Helvetica, Arial, sans-serif",
adLabelM : "\"National2\", Helvetica, Arial, sans-serif",
}

export const fontSizes = {
baseTitlepieceM : "1.75rem",
baseTitlepieceL : "4.375rem",
baseBoostS : "1.75rem",
baseBoostM : "3.75rem",
baseIngressM : "1.625rem",
baseIngressL : "1.625rem",
baseHeadlineXs : "0.875rem",
baseHeadlineS : "1rem",
baseHeadlineM : "1.125rem",
baseHeadlineL : "1.375rem",
baseSubheadS : "0.875rem",
baseSubheadM : "1.375rem",
baseBodyS : "1rem",
baseBodyM : "1.375rem",
baseCaptionS : "0.875rem",
baseCaptionM : "1rem",
baseMetaXs : "0.625rem",
baseMetaS : "0.75rem",
baseMetaM : "0.875rem",
baseMetaL : "1rem",
baseUtilityS : "0.875rem",
baseUtilityXs : "0.875rem",
baseUtilityM : "1.125rem",
baseTabularS : "1rem",
baseTabularM : "1.375rem",
pillM : "0.8125rem",
pillBoldM : "0.8125rem",
pillL : "0.875rem",
pillBoldL : "0.875rem",
titleXs : "1.1875rem",
titleS : "1.16875rem",
titleM : "1.375rem",
titleL : "1.875rem",
titleXl : "2.5rem",
titleXxl : "3.125rem",
titleOpinionXs : "1.625rem",
titleOpinionS : "1.6875rem",
titleOpinionM : "1.5rem",
titleOpinionL : "2.1875rem",
titleOpinionXl : "2.8125rem",
titleOpinionXxl : "2.8125rem",
titleFeatureM : "1.625rem",
titleFeatureXs : "1.3125rem",
titleFeatureS : "1.3125rem",
titleFeatureL : "2.1875rem",
titleFeatureXl : "3.4375rem",
titleFeatureXxl : "3.75rem",
titleFeatureDarkXs : "1.3125rem",
titleFeatureDarkS : "1.3125rem",
titleFeatureDarkM : "1.875rem",
titleFeatureDarkL : "2.5rem",
titleFeatureDarkXl : "3.125rem",
titleFeatureDarkXxl : "3.75rem",
titleBreakingXs : "1.3125rem",
titleBreakingS : "1.3125rem",
titleBreakingM : "1.875rem",
titleBreakingL : "2.1875rem",
titleBreakingXl : "2.5rem",
titleBreakingXxl : "3.125rem",
titleBreakingDarkXs : "1.3125rem",
titleBreakingDarkS : "1.3125rem",
titleBreakingDarkM : "1.875rem",
titleBreakingDarkL : "2.5rem",
titleBreakingDarkXl : "3.125rem",
titleBreakingDarkXxl : "3.125rem",
pretitleXs : "1rem",
pretitleS : "1rem",
pretitleM : "0.875rem",
pretitleL : "1.125rem",
pretitleXl : "1.25rem",
pretitleXxl : "1.25rem",
valgomatHeadline1M : "2rem",
valgomatHeadline1L : "2.25rem",
valgomatHeadline2M : "1.25rem",
valgomatHeadline2L : "1.5rem",
valgomatQuestionM : "1.125rem",
valgomatQuestionL : "1.5rem",
valgomatDetailXs : "0.875rem",
valgomatDetailS : "1rem",
valgomatDetailM : "1.25rem",
valgomatDetailL : "1.5rem",
valgomatDetailXl : "2.25rem",
valgomatUtilityM : "1.125rem",
tipusM : "1.125rem",
groupTitleM : "1.0625rem",
groupTitleL : "1.625rem",
titleCommercialXs : "1.375rem",
titleCommercialXxl : "2.375rem",
avatarMetaS : "0.8125rem",
avatarMetaM : "0.9375rem",
avatarMetaL : "1.125rem",
adLabelM : "0.8125rem",
}

export const fontWeights = {
baseTitlepieceM : 600,
baseTitlepieceL : 600,
baseBoostS : 600,
baseBoostM : 600,
baseIngressM : 500,
baseIngressL : 500,
baseHeadlineXs : 600,
baseHeadlineS : 600,
baseHeadlineM : 700,
baseHeadlineL : 600,
baseSubheadS : 600,
baseSubheadM : 800,
baseBodyS : 400,
baseBodyM : 400,
baseCaptionS : 400,
baseCaptionM : 400,
baseMetaXs : 400,
baseMetaS : 400,
baseMetaM : 400,
baseMetaL : 400,
baseUtilityS : 400,
baseUtilityXs : 400,
baseUtilityM : 400,
baseTabularS : 400,
baseTabularM : 400,
pillM : 400,
pillBoldM : 500,
pillL : 400,
pillBoldL : 500,
titleXs : "SemiBold ",
titleS : "SemiBold ",
titleM : 600,
titleL : "SemiBold ",
titleXl : "SemiBold ",
titleXxl : "SemiBold ",
titleOpinionXs : 400,
titleOpinionS : 400,
titleOpinionM : 600,
titleOpinionL : 400,
titleOpinionXl : 400,
titleOpinionXxl : 400,
titleFeatureM : 400,
titleFeatureXs : 700,
titleFeatureS : 700,
titleFeatureL : 700,
titleFeatureXl : 700,
titleFeatureXxl : 700,
titleFeatureDarkXs : 700,
titleFeatureDarkS : 700,
titleFeatureDarkM : 700,
titleFeatureDarkL : 700,
titleFeatureDarkXl : 700,
titleFeatureDarkXxl : 700,
titleBreakingXs : "SemiBold ",
titleBreakingS : "SemiBold ",
titleBreakingM : "SemiBold ",
titleBreakingL : "SemiBold ",
titleBreakingXl : "SemiBold ",
titleBreakingXxl : "SemiBold ",
titleBreakingDarkXs : "SemiBold ",
titleBreakingDarkS : "SemiBold ",
titleBreakingDarkM : "SemiBold ",
titleBreakingDarkL : "SemiBold ",
titleBreakingDarkXl : "SemiBold ",
titleBreakingDarkXxl : "SemiBold ",
pretitleXs : "SemiBold ",
pretitleS : "SemiBold ",
pretitleM : "SemiBold ",
pretitleL : "SemiBold ",
pretitleXl : "SemiBold ",
pretitleXxl : "SemiBold ",
valgomatHeadline1M : 400,
valgomatHeadline1L : 400,
valgomatHeadline2M : 400,
valgomatHeadline2L : 400,
valgomatQuestionM : 400,
valgomatQuestionL : 400,
valgomatDetailXs : 400,
valgomatDetailS : 400,
valgomatDetailM : 400,
valgomatDetailL : 400,
valgomatDetailXl : 500,
valgomatUtilityM : 400,
tipusM : 400,
groupTitleM : 500,
groupTitleL : 500,
titleCommercialXs : 700,
titleCommercialXxl : 700,
avatarMetaS : 500,
avatarMetaM : 500,
avatarMetaL : 500,
adLabelM : 500,
}

export const letterSpacings ={
baseTitlepieceM : "0rem",
baseTitlepieceL : "0rem",
baseBoostS : "0rem",
baseBoostM : "0rem",
baseIngressM : "0rem",
baseIngressL : "0rem",
baseHeadlineXs : "0rem",
baseHeadlineS : "0rem",
baseHeadlineM : "0rem",
baseHeadlineL : "0rem",
baseSubheadS : "0rem",
baseSubheadM : "0.0099626412551359rem",
baseBodyS : "0rem",
baseBodyM : "-0.0199252825102718rem",
baseCaptionS : "0rem",
baseCaptionM : "0rem",
baseMetaXs : "0rem",
baseMetaS : "0rem",
baseMetaM : "0rem",
baseMetaL : "0rem",
baseUtilityS : "0rem",
baseUtilityXs : "0rem",
baseUtilityM : "0rem",
baseTabularS : "0rem",
baseTabularM : "0rem",
pillM : "normal",
pillBoldM : "0rem",
pillBoldL : "0rem",
titleXs : "0rem",
titleS : "0rem",
titleM : "0rem",
titleL : "0rem",
titleXl : "0rem",
titleXxl : "0rem",
titleOpinionXs : "0rem",
titleOpinionS : "0rem",
titleOpinionM : "0rem",
titleOpinionL : "0rem",
titleOpinionXl : "0rem",
titleOpinionXxl : "0rem",
titleFeatureM : "0rem",
titleFeatureXs : "-0.0099626412551359rem",
titleFeatureS : "-0.0099626412551359rem",
titleFeatureL : "-0.0199252825102718rem",
titleFeatureXl : "-0.0199252825102718rem",
titleFeatureXxl : "-0.0199252825102718rem",
titleFeatureDarkXs : "-0.0099626412551359rem",
titleFeatureDarkS : "-0.0099626412551359rem",
titleFeatureDarkM : "-0.0199252825102718rem",
titleFeatureDarkL : "-0.0199252825102718rem",
titleFeatureDarkXl : "-0.0199252825102718rem",
titleFeatureDarkXxl : "-0.0199252825102718rem",
titleBreakingXs : "0rem",
titleBreakingS : "0rem",
titleBreakingM : "0rem",
titleBreakingL : "0rem",
titleBreakingXl : "0rem",
titleBreakingXxl : "0rem",
titleBreakingDarkXs : "0rem",
titleBreakingDarkS : "0rem",
titleBreakingDarkM : "0rem",
titleBreakingDarkL : "0rem",
titleBreakingDarkXl : "0rem",
titleBreakingDarkXxl : "0rem",
pretitleXs : "0rem",
pretitleS : "0rem",
pretitleM : "0rem",
pretitleL : "0rem",
pretitleXl : "0rem",
pretitleXxl : "0rem",
valgomatHeadline1M : "0rem",
valgomatHeadline1L : "0rem",
valgomatHeadline2M : "",
valgomatHeadline2L : "",
valgomatQuestionM : "",
valgomatQuestionL : "",
valgomatDetailXs : "",
valgomatDetailS : "",
valgomatDetailM : "",
valgomatDetailL : "",
valgomatDetailXl : "",
tipusM : "-0.0099626412551359rem",
groupTitleM : "",
groupTitleL : "-0.0099626412551359rem",
titleCommercialXs : "-0.0099626412551359rem",
titleCommercialXxl : "-0.0099626412551359rem",
avatarMetaS : "",
avatarMetaM : "",
avatarMetaL : "",
adLabelM : "0.049813206275679506rem",
}

export const lineHeights = {
baseTitlepieceM : "1.15",
baseTitlepieceL : "1.05",
baseBoostS : "1.15",
baseBoostM : "1.15",
baseIngressM : "1.2",
baseIngressL : "1.2",
baseHeadlineXs : "1.15",
baseHeadlineS : "1.25",
baseHeadlineM : "1.45",
baseHeadlineL : "1.1",
baseSubheadS : "1.25",
baseSubheadM : "1.2",
baseBodyS : "1.35",
baseBodyM : "1.2",
baseCaptionS : "1.35",
baseCaptionM : "1.35",
baseMetaXs : "1.15",
baseMetaS : "1.15",
baseMetaM : "1.15",
baseMetaL : "1.15",
baseUtilityS : "1.15",
baseUtilityXs : "1.5",
baseUtilityM : "1.35",
baseTabularS : "1.35",
baseTabularM : "1.2",
pillM : "1.2",
pillBoldM : "1.2",
pillL : "1.2",
pillBoldL : "1.2",
titleXs : "1.05",
titleS : "1.05",
titleM : "1.1",
titleL : "1.05",
titleXl : "1.05",
titleXxl : "1.05",
titleOpinionXs : "1.05",
titleOpinionS : "1",
titleOpinionM : "1",
titleOpinionL : "1.05",
titleOpinionXl : "1.05",
titleOpinionXxl : "1.05",
titleFeatureM : "1.05",
titleFeatureXs : "1.05",
titleFeatureS : "1.05",
titleFeatureL : "1.05",
titleFeatureXl : "1.05",
titleFeatureXxl : "1.05",
titleFeatureDarkXs : "1.05",
titleFeatureDarkS : "1.05",
titleFeatureDarkM : "1.05",
titleFeatureDarkL : "1.05",
titleFeatureDarkXl : "1.05",
titleFeatureDarkXxl : "1.05",
titleBreakingXs : "1.05",
titleBreakingS : "1.05",
titleBreakingM : "1.05",
titleBreakingL : "1.05",
titleBreakingXl : "1.05",
titleBreakingXxl : "1.05",
titleBreakingDarkXs : "1.05",
titleBreakingDarkS : "1.05",
titleBreakingDarkM : "1.05",
titleBreakingDarkL : "1.05",
titleBreakingDarkXl : "1.05",
titleBreakingDarkXxl : "1.05",
pretitleXs : "1.15",
pretitleS : "1.15",
pretitleM : "1.15",
pretitleL : "1.15",
pretitleXl : "1.15",
pretitleXxl : "1.15",
valgomatHeadline1M : "1.25",
valgomatHeadline1L : "1.25",
valgomatHeadline2M : "1.5",
valgomatHeadline2L : "1.5",
valgomatQuestionM : "1.5",
valgomatQuestionL : "1.5",
valgomatDetailXs : "1.5",
valgomatDetailS : "1.5",
valgomatDetailM : "1.5",
valgomatDetailL : "1.5",
valgomatDetailXl : "1.5",
valgomatUtilityM : "1.5",
tipusM : "1.1",
groupTitleM : "1.05",
groupTitleL : "1.05",
titleCommercialXs : "1.1",
titleCommercialXxl : "1.1",
avatarMetaS : "1.05",
avatarMetaM : "1.05",
avatarMetaL : "1.05",
}
